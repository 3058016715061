import React from "react";
import "./Footer.css";
export default function Footer() {
  return (
    <div className="footer">
      <p className="footer-p">
        COPYRIGHT © 2017 - 2025 TRANSGLOBAL EXPRESS , LLC. ALL RIGHTS RESERVED{" "}
      </p>
    </div>
  );
}
